<template>
  <div>
        <el-form :model="form" ref="addressForm" label-width="110px" :key="formKey">
          <el-form-item label="收件人" prop="user_name" :rules="[ { required: true, message: '此项不能为空'} ]">
            <el-input v-model="form.user_name" autocomplete="off" placeholder="收件人姓名"></el-input>
          </el-form-item>
          <el-form-item label="地址区域" prop="area_code" :rules="[{ required: true, message: '此项不能为空'}]">
            <el-cascader
              style="width:100%"
              v-model="form.area_code"
              :options="areaSelectData"
              :props="{ expandTrigger: 'hover',label:'name',children:'children',value:'code' }"
              filterable
              placeholder="请选择"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address_detail" :rules="[ { required: true, message: '此项不能为空'} ]">
            <el-input v-model="form.address_detail" autocomplete="off" placeholder="如xxx路xx号"></el-input>
          </el-form-item>
          <el-form-item label="电话/手机" prop="user_phone" :rules="[ { required: true, message: '此项不能为空'} ]">
            <el-input v-model="form.user_phone" autocomplete="off" placeholder="收件人联系方式"></el-input>
          </el-form-item>
          <el-form-item label="设为默认地址" prop="user_phone">
             <el-switch v-model="form.is_default" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addressForm')">提交</el-button>
            <el-button @click="resetForm('addressForm')">重置</el-button>
          </el-form-item>
        </el-form>
  </div>
</template>

<script>

export default {
  name: 'addressForm',
  components: {},
  props:{
    q:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      formKey:Math.random(),
      areaSelectData:[],
      form:{}
    }
  },
  computed:{
    area_text(){
      let obj=null,
          area_text=[]
      if(this.form.area_code&&this.form.area_code.length&&this.areaSelectData.length){
        this.form.area_code.forEach((code,index) => {
          if(index==0){
            obj=this.areaSelectData.find(v=>{return v.code==code})
          }else{
            obj=obj.children.find(v=>{return v.code==code})
          }
          area_text.push(obj.name)
        });  
      }
      return area_text
    }
  },
  methods:{
    initData(){
      this.form=JSON.parse(JSON.stringify(this.q))
      this.$set(this.form,'area_code',[])
      let area_code=[this.form.province_code,this.form.city_code,this.form.district_code]
      for(let code of area_code){
        if(code){
          this.form.area_code.push(Number(code))
        }
      }
    },
    //获取地区选择器数据
    getAreaData(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/address/get_district_config',{params})
      .then(res=>{
       
        if(res.data.code==0){
          this.areaSelectData=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.q.address_id){
              this.updateAddress()
            }else{
              this.saveAddress()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //新增地址
    saveAddress(){
      let params= {
        ...this.$store.state.basicParams,
        province_code:this.form.area_code[0]||'',
        city_code:this.form.area_code[1]||this.form.area_code[0]||'',
        district_code:this.form.area_code[2]||this.form.area_code[1]||this.form.area_code[0]||'',
        province:this.form.province||'',
        city:this.form.city||this.form.province||'',
        district:this.form.district||this.form.city||this.form.province||'',
        address_detail:this.form.address_detail||'',
        user_name:this.form.user_name||'',
        user_phone:this.form.user_phone||'',
        is_default:this.form.is_default||0,
      }
      this.$instance.post('/address/save_user_address',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          this.successCallBack()
        }
      })
      .catch(()=> {}); 
    },
    //更新地址
    updateAddress(){
      let params= {
        ...this.$store.state.basicParams,
        address_id:this.form.address_id||'',
        province_code:this.form.area_code[0]||'',
        city_code:this.form.area_code[1]||this.form.area_code[0]||'',
        district_code:this.form.area_code[2]||this.form.area_code[1]||this.form.area_code[0]||'',
        province:this.form.province||'',
        city:this.form.city||this.form.province||'',
        district:this.form.district||this.form.city||this.form.province||'',
        address_detail:this.form.address_detail||'',
        user_name:this.form.user_name||'',
        user_phone:this.form.user_phone||'',
        is_default:this.form.is_default||0,
      }
      this.$instance.post('/address/update_user_address',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          this.successCallBack()
        }
      })
      .catch(()=> {}); 
    },
    successCallBack(){
      this.$emit("success",JSON.parse(JSON.stringify(this.form)))
    }
  },
  created(){
   this.getAreaData()
   this.initData()
  },
  mounted(){

  },
  watch: {
    'q':{
      handler(){
        this.initData()
        this.formKey=Math.random()
      },
      deep:true
    }, 
    'area_text':{
      handler(){
        this.form.province=this.area_text[0]||''
        this.form.city=this.area_text[1]||''
        this.form.district=this.area_text[2]||''
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

</style>
